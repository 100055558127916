(function () {
  'use strict';

  /* global $ */

  function highlightText(selector, text) {
    var $matches = $(selector).filter(':contains(' + text + ')');
    $matches.each(function() {
      var regexp = new RegExp('^\\s*(' + text + ')' + '(.*)');
      var match = $(this).text().match(regexp);
      if (match) {
        $(this).html('<b>' + match[1] + '</b>' + match[2]);
      }
    });
  }

  function emulateHoverOnMobile(selector, sub) {
    $(selector).on('touchstart', function(evt) {
      var isPreviousHover = $(this).hasClass('hover');
      var isSub = $(evt.target).closest(sub).length;
      $(selector).removeClass('hover');
      $(this).addClass('hover');
      if (isSub || isPreviousHover) {
        return;
      }
      evt.preventDefault();
    });
  }

  function ContactForm(id) {
    this.$el = $(id);
    if (this.$el.length < 1) {
      return;
    }

    var prefix = id.substr(1);
    this.$el.on('submit', this.onSubmit.bind(this));
    this.$content = this.$el.find('.' + prefix + '-content');
    this.$confirmation = this.$el.find('.' + prefix + '-confirmation');
    this.$error = this.$el.find('.' + prefix + '-error');
  }

  ContactForm.prototype.onSubmit = function(evt) {
    function blur() {
      $(evt.target).find('[type="submit"]').blur();
    }

    $.post(this.$el.attr('action'), this.$el.serialize()).then(function () {
      blur();
      this.$content.hide();
      this.$confirmation.show();
    }.bind(this), function(evt) {
      blur();
      this.$error.show();
    }.bind(this));

    return false;
  }

  function initialize() {
    highlightText('.nav a, .breadcrumb li.current, .breadcrumb li a', 'Fontus');
    highlightText('.nav a, .breadcrumb li.current, .breadcrumb li a', 'Wessendorf');
    emulateHoverOnMobile('.nav ul.nav-list > li', '.nav-sub');
    new ContactForm('#contact-form');
  }

  // Run initialize on Neos page load (which is not a full refresh)
  if (typeof document.addEventListener === 'function') {
    document.addEventListener('Neos.PageLoaded', initialize, false);
  }

  $(document).ready(initialize);
})();
